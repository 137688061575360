jQuery(document).ready(function($){

/**
 * Verificar se nome da entidade já existe
 */
$('#entidade-nome').on('keyup', function(){

	var id = parseInt($('#entidade-id').val());
	var var_nome = $('#entidade-nome').val();
	var campo_bd = 'nome';
	var tabela = 'entidades';

	if (var_nome) {
		$.ajax({
			type: 'post',
			url: '../../paletes/inc_admin/check_entidade_nome.php',
			data: {
				id: id,
				var_nome: var_nome,
				campo_bd: campo_bd,
				tabela: tabela
			},
			success: function(response){
				$('#entidade-nome-status').html(response);

				if (response == 'ok') {
					return true;	
				} 
				else {
					return false
				}
			}

		});
	}
	else{
		$('#entidade-nome-status').html('');
		return false;
	}
});
/**
 * Fim verificar se nome da entidade já existe
 */


/**
 * Verificar se nif da entidade já existe
 */
$('#entidade-nif').on('keyup', function(){

	var id = $('#entidade-id').val();
	var var_nome = $('#entidade-nif').val();
	var campo_bd = 'nif';
	var tabela = 'entidades';

	if (var_nome) {
		$.ajax({
			type: 'post',
			url: '../../paletes/inc_admin/check_entidade_nome.php',
			data: {
				id: id,
				var_nome: var_nome,
				campo_bd: campo_bd,
				tabela: tabela
			},
			success: function(response){
				$('#entidade-nif-status').html(response);

				if (response == 'ok') {
					return true;	
				} 
				else {
					return false
				}
			}

		});
	}
	else{
		$('#entidade-nif-status').html('');
		return false;
	}
});
/**
 * Fim verificar se nif da entidade já existe
 */

/**
 * Tirar aviso de erro no input
 */
$(document).on('focus', '.input-control', function(){

    $(this).removeClass('errorvazio');
});

/**
 * Verificações ao gravar entidade
 */
$('#gravar-entidade').on('click', function(){

	$('.avisos').html('');

	var erro = '';
	var texto = '';
	var nome = $('#entidade-nome').val();
	var entidade_nome_status = $('#entidade-nome-status').html();
	var nif = $('#entidade-nif').val();
	var entidade_nif_status = $('#entidade-nif-status').html();
	var chep = $('#checkbox-chep');
	var lpr = $('#checkbox-lpr');
	var cb_cliente_chep = $('#checkbox-cliente-chep');
	var cb_fornecedor_chep = $('#checkbox-fornecedor-chep');
	var cb_cliente_lpr = $('#checkbox-cliente-lpr');
	var cb_fornecedor_lpr = $('#checkbox-fornecedor-lpr');
	//console.log(entidade_nome_status);

	/**
	 * Campo nome
	 */
	if (!nome) {
		erro = 'sim';
		$('#entidade-nome').addClass('errorvazio');
		$('#entidade-nome-status').html('Campo obrigatório');

		texto = 'Preencha o campo - nome.';

		avisos(texto);
	}
	else if(nome && entidade_nome_status != ''){

		if (entidade_nome_status != 'ok') {

			erro = 'sim';
			$('#entidade-nome').addClass('errorvazio');

			texto = nome + ' já existe.';

			avisos(texto);
		}
	}
	/**
	 * Fim campo nome
	 */

	/**
	 * Campo nif
	 */
	if (nif && entidade_nif_status != '') {

		if (entidade_nif_status != 'ok') {

			erro = 'sim';
			$('#entidade-nif').addClass('errorvazio');

			texto = nif + ' já existe.';

			avisos(texto);
		}
	}
	/**
	 * Fim campo nif
	 */
	
	/**
	 * Movimentos Chep/LPR
	 */
	if (!chep.is(":checked") && !lpr.is(":checked")) {
		erro = 'sim';

		texto = 'A entidade tem de ser pelo menos um dos seguintes movimentos: Chep ou LPR.';

		avisos(texto);
	}
	/**
	 * Fim movimentos Chep/LPR
	 */
	
	/**
	 * Chep detail
	 */
	if (chep.is(":checked") && !cb_cliente_chep.is(":checked") && !cb_fornecedor_chep.is(":checked")) {
		erro = 'sim';

		texto = 'A entidade foi declarada com tendo movimentos Chep.<br>'+
				'Como tal, tem de ser Cliente ou Fornecedor';

		avisos(texto);
	}
	/**
	 * Fim Chep detail
	 */
	
	/**
	 * LPR detail
	 */
	if (lpr.is(":checked") && !cb_cliente_lpr.is(":checked") && !cb_fornecedor_lpr.is(":checked")) {
		erro = 'sim';

		texto = 'A entidade foi declarada com tendo movimentos LPR.<br>'+
				'Como tal, tem de ser Cliente ou Fornecedor';

		avisos(texto);
	}
	/**
	 * Fim LPR detail
	 */

	/**
	 * Se houver algum erro nas verificações anteriores retorna falso
	 */
	if (erro) {
        return false;
    }


});
/**
 * Fim verificações ao gravar entidade
 */


/**
 * Verificar se modelo da palete já existe
 */
$('#modelo').on('keyup', function(){

	var id = parseInt($('#palete-id').val());
	var var_nome = $('#modelo').val();
	var campo_bd = 'modelo';
	var tabela = 'pal';

	if (var_nome) {
		$.ajax({
			type: 'post',
			url: '../../paletes/inc_admin/check_entidade_nome.php',
			data: {
				id: id,
				var_nome: var_nome,
				campo_bd: campo_bd,
				tabela: tabela
			},
			success: function(response){
				$('#modelo-status').html(response);

				if (response == 'ok') {
					return true;	
				} 
				else {
					return false
				}
			}

		});
	}
	else{
		$('#modelo-status').html('');
		return false;
	}
});
/**
 * Fim verificar se modelo da palete já existe
 */

/**
 * Verificar se a pelete já tem movimetos.
 * Se já tiver, não posso alterar o fornecedor
 */

$('#fornecedor').on('change', function(){

	var forn_nome = $(this).val();
	var forn_original = $('#forn-original').val();
	
	var palete_id = $('#palete-id').val();

	if (palete_id && forn_nome != forn_original) {

		$.ajax({
			type: 'post',
			url: '../../paletes/inc_admin/check_pal_mov.php',
			data: {
				id: palete_id
			},
			success: function(response){
				
				$('#mov-check').val(response);
				
			}

		});
	}
	else{
		$('#mov-check').val('ok');
	}

});


/**
 * Fim Verificar se a pelete já tem movimetos.
 * Se já tiver, não posso alterar o fornecedor
 */


/**
 * Verificações ao gravar paletes 
 */
$('#gravar-palete').on('click', function(){

	$('.avisos').html('');

	var erro = '';
	var texto = '';
	var fornecedor = $('#fornecedor').val();
	var modelo = $('#modelo').val();
	var modelo_status = $('#modelo-status').html();
	var ja_tem_mov = $('#mov-check').val();

	//console.log(fornecedor);

	/**
	 * Campo fornecedor
	 */
	if (!fornecedor) {
		erro = 'sim';
		$('#fornecedor').addClass('errorvazio');

		texto = 'Seleccione um fornecedor';

		avisos(texto);
		
	}
	/**
	 * Fim campo fornecedor
	 */

	/**
	 * Campo modelo
	 */
	if (!modelo) {
		erro = 'sim';
		$('#modelo').addClass('errorvazio');

		texto = 'Preencha o campo modelo';

		avisos(texto);
	}
	else if(modelo && modelo_status != ''){

		if (modelo_status != 'ok') {

			erro = 'sim';
			$('#modelo').addClass('errorvazio');
			$('#modelo-status').html('Campo obrigatório');

			texto = modelo + ' já existe.';

			avisos(texto);
		}
	}
	/**
	 * Fim campo modelo
	 */
	
	/**
	 * Já tem mov
	 */
	if (ja_tem_mov == 'jatemmov') {
		erro = 'sim';
		texto = 'A palete - <b>' + modelo + ' </b>- já tem movimentos.<br>'+
		 'Como tal, o fornecedor não pode ser alterado.';
		avisos(texto);
	}

	/**
	 * Fim já tem mov
	 */

	/**
	 * Se houver algum erro nas verificações anteriores retorna falso
	 */
	if (erro) {
        return false;
    }


});
/**
 * Fim verificações ao gravar paletes
 */

/**
 * Verificações ao gravar movimento
 */
$('#gravar-mov').on('click', function(){

	$('.avisos').html('');

	var erro = '';
	var texto = '';
	var tipo_mov = $('#tipo-mov').val();
	var data = $('#data').val();
	var entidade = $('#entidade').val();
	var guia = $('#guia').val();
	var palete = $('#palete').val();
	var qtd_real = $('#qtd-real').val();
	var qtd_alt = $('#qtd-alt').val();
	//console.log(entidade_nome_status);

	/**
	 * Campo tipo_mov
	 */
	if (!tipo_mov) {
		erro = 'sim';
		$('#tipo-mov').addClass('errorvazio');

		texto = 'Seleccione o campo - tipo mov.';

		avisos(texto);
	}
	/**
	 * Fim campo tipo_mov
	 */
	
	/**
	 * Campo data
	 */
	if (!data) {
		erro = 'sim';
		$('#data').addClass('errorvazio');

		texto = 'Seleccione uma data.';

		avisos(texto);
	}
	/**
	 * Fim campo data
	 */
	
	/**
	 * Campo entidade
	 */
	if (!entidade) {
		erro = 'sim';
		$('#entidade').addClass('errorvazio');

		texto = 'Seleccione o campo - entidade.';

		avisos(texto);
	}
	/**
	 * Fim campo entidade
	 */
	
	/**
	 * Campo palete
	 */
	if (!palete) {
		erro = 'sim';
		$('#palete').addClass('errorvazio');

		texto = 'Seleccione o campo - palete.';

		avisos(texto);
	}
	/**
	 * Fim campo palete
	 */
	
	/**
	 * Campo qtd_real
	 */
	if (!$.isNumeric(qtd_real)) {
		erro = 'sim';
		$('#qtd-real').addClass('errorvazio');

		texto = 'O campo qtd real tem de ser numérico e é obrigatório.';

		avisos(texto);
	}
	/**
	 * Fim campo qtd_real
	 */
	
	/**
	 * Campo qtd_alt
	 */
	if (!$.isNumeric(qtd_alt)) {
		erro = 'sim';
		$('#qtd-alt').addClass('errorvazio');

		texto = 'O campo qtd alt tem de ser numérico e é obrigatório.';

		avisos(texto);
	}
	/**
	 * Fim campo qtd_alt
	 */

	/**
	 * Campo guia
	 */
	 if (guia) {
	
		id = $('#id').val();
		var_nome = guia;
		var_nome_forn = $('#fornecedor').val();
		campo_bd = 'guia';
		campo_bd_forn = 'fornecedor';
		tabela = 'movimentos';

		$.ajax({
			type: 'post',
			url: '../../paletes/inc_admin/check_guia.php',
			data: {
				id: id,
				var_nome: var_nome,
				var_nome_forn: var_nome_forn,
				campo_bd: campo_bd,
				campo_bd_forn: campo_bd_forn,
				tabela: tabela
			},
			success: function(response){
				
				//console.log(response);
				if (response == 'ok') {
					return 'ok';	
					
				} 
				else {

					erro = 'sim';
					$('#guia').addClass('errorvazio');

					texto = 'A guia ' + var_nome + ' já existe';
					avisos(texto);
					return false;
					

				}
			}


		});
	}
	
	
	/**
	 * FimCampo guia
	 */

	/**
	 * Se houver algum erro nas verificações anteriores retorna falso
	 */
	if (erro) {
        return false;
    }


});
/**
 * Fim verificações ao gravar movimento
 */


/**
 * Verificação do filtro entre datas
 */
$('#filtro').on('click', function(){

	$('.avisos').html('');
	var erro = '';
	var texto = '';
	var data_inicio = $('#data-inicio').val();
	var data_fim = $('#data-fim').val();
	
	if (!data_inicio) {
		erro = 'sim';
		$('#data-inicio').addClass('errorvazio');
		texto = 'A Data de Inicio tem de ser preenchida';

		avisos(texto);
	}
	if (!data_fim) {
		erro = 'sim';
		$('#data-fim').addClass('errorvazio');
		texto = 'A Data de Fim tem de ser preenchida';

		avisos(texto);
	}

	if (data_inicio > data_fim) {
		erro = 'sim';
		$('#data-inicio').addClass('errorvazio');
		$('#data-fim').addClass('errorvazio');
		texto = 'A Data de Inicio não pode ser maior que a Data de Fim';

		avisos(texto);
	}

	if (erro) {
        return false;
    }

});
/**
 * Fim Verificação do filtro entre datas
 */

function avisos(texto){

	$('.avisos').append('<div class="alert alert-danger alert-dismissible fade show" role="alert">'+
						 texto +
						 '<button type="button" class="close" data-dismiss="alert" aria-label="Close">'+
                         '<span aria-hidden="true">&times;</span>'+
                         '</button>'+
                         '</div>'
			);

}


}); //jQuery