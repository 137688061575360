jQuery(document).ready(function($){




//FORMATAR COLUNAS COM SEPARADOR DE MILHAR
jQuery.extend( jQuery.fn.dataTableExt.oSort, {
    "formatted-num-pre": function ( a ) {
        a = (a === "-" || a === "") ? 0 : a.replace(/[.$£€]/g,'').replace(/,/g,'.');
        return parseFloat( a );
    },
 
    "formatted-num-asc": function ( a, b ) {
        return a - b;
    },
 
    "formatted-num-desc": function ( a, b ) {
        return b - a;
    }
} );


//######################## TABELA CHEP ENTIDADES FORNECEDORES #########################################

    var jpdt_fornecedores = $('.jpdt-fornecedores').DataTable({
    	dom: "lrtip",
    	"paging": false,
       	"ordering": false,
       	"info": false,
       	"autoWidth" : false,
        select: true,
        'responsive': true,
        "columnDefs": [
        	{ "width": "1%", "targets": [0,1] },
    		{ "width": "50%", "targets": [2,3] }
  		],

  		"language": {
                    "emptyTable": "Não foram encontrados resultados",
                    "zeroRecords": "Não foram encontrados resultados",
        },

        "footerCallback": function ( row, data, start, end, display ) {
            var api = this.api(), data;
 
            // Remove the formatting to get integer data for summation
            var intVal = function ( i ) {
                return typeof i === 'string' ?
                    i.replace(/[\$,]/g, '')*1 :
                    typeof i === 'number' ?
                        i : 0;
            };
 
            // Total over all pages
            total = api
                .column( 2 )
                .data()
                .reduce( function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0 );
 
             // Total qtd_real over search
           	qtdReal = api
                .column( 2, { search: 'applied'} )
                .data()
                .reduce( function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0 );
 
            // Update footer qtd_real
            $( api.column( 2 ).footer() ).html(
                qtdReal
            );

            // Total qtd_alt over search
            qtdAlt = api
                .column( 3, { search: 'applied'} )
                .data()
                .reduce( function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0 );
 
            // Update footer qtd_alt
            $( api.column( 3 ).footer() ).html(
                qtdAlt
            );
        }
        
    });

    //Aplicar filtros para cada coluna no footer 
    $(".jpdt-fornecedores tfoot th").each( function ( i ) {
     
        var tipo = $(this).attr('tipo');

        if(tipo === 'select'){
        	
        	var select = $('<select class="filters"><option value="">Filtro</option></select>')
        		.prependTo( $(this).empty() )
        		.on( 'change', function() {
        			var val = $.fn.dataTable.util.escapeRegex(
        				$(this).val()
        			);

        			jpdt_fornecedores.column(i)
        				.search(val ? '^'+val+'$' : '', true, false)
        				.draw();
        		});

        		jpdt_fornecedores.column(i).data().unique().sort().each(function (d, j) {
        			select.append('<option value="'+d+'">'+d+'</option>')
        		});
        }
        
    } );

//######################## TABELA CHEP ENTIDADES CLIENTES #########################################
    
    var jpdt_clientes = $('.jpdt-clientes').DataTable({
    	dom: "lrtip",
    	"paging": false,
       	"ordering": false,
       	"info": false,
       	"autoWidth" : false,
        select: true,
        'responsive': true,
        "columnDefs": [
        	{ "width": "1%", "targets": [0,1] },
    		{ "width": "50%", "targets": [2,3] }
  		],

  		"language": {
                    "emptyTable": "Não foram encontrados resultados",
                    "zeroRecords": "Não foram encontrados resultados",
        },

        "footerCallback": function ( row, data, start, end, display ) {
            var api = this.api(), data;
 
            // Remove the formatting to get integer data for summation
            var intVal = function ( i ) {
                return typeof i === 'string' ?
                    i.replace(/[\$,]/g, '')*1 :
                    typeof i === 'number' ?
                        i : 0;
            };
 
            // Total over all pages
            total = api
                .column( 2 )
                .data()
                .reduce( function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0 );
 
             // Total qtd_real over search
           	qtdReal = api
                .column( 2, { search: 'applied'} )
                .data()
                .reduce( function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0 );
 
            // Update footer qtd_real
            $( api.column( 2 ).footer() ).html(
                qtdReal
            );

            // Total qtd_alt over search
            qtdAlt = api
                .column( 3, { search: 'applied'} )
                .data()
                .reduce( function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0 );
 
            // Update footer qtd_alt
            $( api.column( 3 ).footer() ).html(
                qtdAlt
            );
        }
        
    });

    //Aplicar filtros para cada coluna no footer 
    $(".jpdt-clientes tfoot th").each( function ( i ) {
     
        var tipo = $(this).attr('tipo');

        if(tipo === 'select'){
        	
        	var select = $('<select class="filters"><option value="">Filtro</option></select>')
        		.prependTo( $(this).empty() )
        		.on( 'change', function() {
        			var val = $.fn.dataTable.util.escapeRegex(
        				$(this).val()
        			);

        			jpdt_clientes.column(i)
        				.search(val ? '^'+val+'$' : '', true, false)
        				.draw();
        		});

        		jpdt_clientes.column(i).data().unique().sort().each(function (d, j) {
        			select.append('<option value="'+d+'">'+d+'</option>')
        		});
        }
        
    } );
    

    $(document).on('keyup change', '.filters', function(){

        if ($(this).val()!= '') {
            $(this).addClass('filtro-active');
        }
        else{
            $(this).removeClass('filtro-active');
        }
    });






}); //jQuery