jQuery(document).ready(function($){

//FORMATAR COLUNAS COM SEPARADOR DE MILHAR
jQuery.extend( jQuery.fn.dataTableExt.oSort, {
    "formatted-num-pre": function ( a ) {
        a = (a === "-" || a === "") ? 0 : a.replace(/[.$£€]/g,'').replace(/,/g,'.');
        return parseFloat( a );
    },
 
    "formatted-num-asc": function ( a, b ) {
        return a - b;
    },
 
    "formatted-num-desc": function ( a, b ) {
        return b - a;
    }
} );


//############################## TABELA FATURACAO LISTA #########################################

// Tabela

    var jpdt_admin_ents = $('.jpdt-admin-ents').DataTable({

        'order': [],
        "lengthMenu": [ [-1, 500, 400, 300, 200, 100, 50], ["Todos", 500, 400, 300, 200, 100, 50] ],
        pageLength:200,
        select: true,
        'responsive': true,
        //'scrollX': true,
        // fixedHeader:true,
        dom: 'Blrtip',

		buttons: [
     
	        {
	            extend: 'pdf',
                footer: true,
	            exportOptions: {
	                columns: ':visible'
	            },
	            orientation: 'landscape',
	        },

	        {
	            text: 'Excel',
	            extend: 'excelHtml5',
	            footer: true,
	            exportOptions: {
	                columns: ':visible'
	            }
	        },

            {
                text: 'Imprimir',
                extend: 'print',
                footer: true,
                exportOptions: {
                    columns: ':visible'
                }
            },
	    
	        {
	            extend: 'colvis',
	            text: '',
	            className: 'btn btn-primary',
	            postfixButtons: [ 'colvisRestore' ],
	            columns: ':visible'
      
	        }
	    ],

        "language": {
                    "paginate": {
                                "next": "Próxima",
                                "previous": "Anterior"
                    },
                    "search": "Pesquisa:",
                    "lengthMenu": "_MENU_ linhas por página",
                    "info": "A mostrar _START_ a _END_ de _TOTAL_ registos",
                    "infoFiltered": "(Filtrado de _MAX_ registos)",
                    "infoEmpty": "Sem resultados",
                    "emptyTable": "Não foram encontrados resultados",
                    "zeroRecords": "Não foram encontrados resultados",
                    "select":{"rows": {
		                    		0: "",
		                    		1: "1 linha seleccionada",
		                    		_: "%d linhas seleccionadas"
		                    	}
                    		},
                    buttons:{colvisRestore: 'Ver tudo'}
        },
        columnDefs: [
            { "orderable": false, "targets": [] },
             //{ "visible": false, "targets": 7 }
            //{ type: 'formatted-num', targets: 5 },
            //{ type: 'formatted-num', targets: 8 },
            //{ type: 'formatted-num', targets: 10 },
            //{render: $.fn.dataTable.render.number( '.', ',', 2, '', ' €' ), targets: [5, 6]},
            //{render: $.fn.dataTable.render.number( '.', ',', 0,  ), targets: [4, 8, 10, 11]},
            //{render: $.fn.dataTable.render.number( '.', ',', 2,  ), targets: [11]},
          
        ],

       


    });

    //Aplicar filtros para cada coluna no topo da tabela

    $(".jpdt-admin-ents thead td").each( function ( i ) {
        
        var tipo = $(this).attr('tipo');

		 if (tipo === 'filtro'){
            var input = $('<input class="filters input1" type="text" placeholder="Filtro...">')
            
            .prependTo( $(this).empty() )
            .on( 'keyup change', function () {

                    var val = $(this).val();

                    jpdt_admin_ents.column( i )
                    .search( val )
                    .draw();
           
            } );

        }
        else if(tipo === 'select'){
        	
        	var select = $('<select class="filters"><option value="">Filtro</option></select>')
        		.prependTo( $(this).empty() )
        		.on( 'change', function() {
        			var val = $.fn.dataTable.util.escapeRegex(
        				$(this).val()
        			);
        			
        			jpdt_admin_ents.column(i)
        				.search(val ? '^'+val+'$' : '', true, false)
        				.draw();
        		});

        		jpdt_admin_ents.column(i).data().unique().sort().each(function (d, j) {

        			select.append('<option value="'+d+'">'+d+'</option>')
        		});
        }

        else if(tipo === 'select-inside'){
        	
        	var select = $('<select class="filters"><option value="">Filtro</option></select>')
        		.prependTo( $(this).empty() )
        		.on( 'change', function() {
        			var val = $.fn.dataTable.util.escapeRegex(
        				$(this).val()
        			);
        			
        			jpdt_admin_ents.column(i)
        				.search(val ? '^'+val+'$' : '', true, false)
        				.draw();
        		});

        		jpdt_admin_ents.column(i).data().unique().sort().each(function (d, j) {
        			var subStr = d.match(">(.*)</");

        			var nome = subStr[1];

        			select.append('<option value="'+nome+'">'+nome+'</option>')
        		});
        }
        
    } );
  
    //Fim Aplicar filtros para cada coluna no topo da tabela


    $(document).on('keyup change', '.filters', function(){

        if ($(this).val()!= '') {
            $(this).addClass('filtro-active');
        }
        else{
            $(this).removeClass('filtro-active');
        }
    });


    //Ir para o topo da tabela quando se clica em 'anterior', 'nº página', 'próximo'
	jpdt_admin_ents.on('page.dt', function() {
	  $('html, body').animate({
	    scrollTop: $(".dataTables_wrapper").offset().top
	  }, 'slow');
	});



}); //jQuery