jQuery(document).ready(function($){

//FORMATAR COLUNAS COM SEPARADOR DE MILHAR
jQuery.extend( jQuery.fn.dataTableExt.oSort, {
    "formatted-num-pre": function ( a ) {
        a = (a === "-" || a === "") ? 0 : a.replace(/[.$£€]/g,'').replace(/,/g,'.');
        return parseFloat( a );
    },
 
    "formatted-num-asc": function ( a, b ) {
        return a - b;
    },
 
    "formatted-num-desc": function ( a, b ) {
        return b - a;
    }
} );


//############################## TABELA FATURACAO LISTA #########################################

// Tabela

    var jpdt_lpr_excel = $('.jpdt-lpr-excel').DataTable({

        'order': [],
        "lengthMenu": [ [-1, 500, 400, 300, 200, 100, 50], ["Todos", 500, 400, 300, 200, 100, 50] ],
        pageLength:200,
        select: true,
        'responsive': true,
        //'scrollX': true,
        // fixedHeader:true,
        dom: 'Blrtip',

		buttons: [
     
	        {
	            extend: 'pdf',
                footer: true,
	            exportOptions: {
	                columns: ':visible'
	            },
	            orientation: 'landscape',
	        },

	        {
	            text: 'Excel',
	            extend: 'excelHtml5',
	            footer: true,
	            exportOptions: {
	                columns: ':visible'
	            }
	        },

            {
                text: 'Imprimir',
                extend: 'print',
                footer: true,
                exportOptions: {
                    columns: ':visible'
                }
            },
	    
	        {
	            extend: 'colvis',
	            text: '',
	            className: 'btn btn-primary',
	            postfixButtons: [ 'colvisRestore' ],
	            columns: ':visible'
      
	        }
	    ],

        "language": {
                    "paginate": {
                                "next": "Próxima",
                                "previous": "Anterior"
                    },
                    "search": "Pesquisa:",
                    "lengthMenu": "_MENU_ linhas por página",
                    "info": "A mostrar _START_ a _END_ de _TOTAL_ registos",
                    "infoFiltered": "(Filtrado de _MAX_ registos)",
                    "infoEmpty": "Sem resultados",
                    "emptyTable": "Não foram encontrados resultados",
                    "zeroRecords": "Não foram encontrados resultados",
                    "select":{"rows": {
		                    		0: "",
		                    		1: "1 linha seleccionada",
		                    		_: "%d linhas seleccionadas"
		                    	}
                    		},
                    buttons:{colvisRestore: 'Ver tudo'}
        },
        columnDefs: [
            { "orderable": false, "targets": [] },
             //{ "visible": false, "targets": 7 }
            //{ type: 'formatted-num', targets: 5 },
            //{ type: 'formatted-num', targets: 8 },
            //{ type: 'formatted-num', targets: 10 },
            {render: $.fn.dataTable.render.number( '.', ',', 0, '' ), targets: [4]},
            //{render: $.fn.dataTable.render.number( '.', ',', 0,  ), targets: [4, 8, 10, 11]},
            //{render: $.fn.dataTable.render.number( '.', ',', 2,  ), targets: [11]},
          
        ],

        "footerCallback": function ( row, data, start, end, display ) {
            var api = this.api(), data;
 
            // Remove the formatting to get integer data for summation
            var intVal = function ( i ) {
                return typeof i === 'string' ?
                    i.replace(/[\$,]/g, '')*1 :
                    typeof i === 'number' ?
                        i : 0;
            };
 
            //Total over all pages
            total = api
                .column( 4 )
                .data()
                .reduce( function (a, b) {
                    return intVal(a) + intVal(b);
                }, 0 );


            // Total qtd_alt saida over search
            qtdAltSai = api
                .column( 4, { search: 'applied'} )
                .data()
                .reduce( function (a, b) {  
                    return intVal(a) + intVal(b);                 
                }, 0 );


            $( api.column( 4 ).footer() ).html(
                qtdAltSai
            );
        },


    });

    //Aplicar filtros para cada coluna no topo da tabela
/*
    $(".jpdt-lpr-excel thead td").each( function ( i ) {
       
        var nome = $(this).attr('nome');
        var tipo = $(this).attr('tipo');

        if (tipo === 'minmax') {
            var input_minmax = $('<input id="min-'+nome+'" tipo="'+tipo+'" class="filters min" type="text" placeholder="min"><input id="max-'+nome+'" tipo="'+tipo+'" class="filters max" type="text" placeholder="max">');
            input_minmax.prependTo( $(this).empty());
        }
        else if (tipo === 'filtro'){
            var input = $('<input class="filters input1" type="text" placeholder="Filtro...">')
            
            .prependTo( $(this).empty() )
            .on( 'keyup change', function () {

                    var val = $(this).val();

                    jpdt_lpr_excel.column( i )
                    .search( val )
                    .draw();
           
            } );

        }
        else if (tipo === 'filtro-anterior'){
            var input = $('<input class="filters input1" type="text" placeholder="Filtro...">')
            
            .prependTo( $(this).empty() )
            .on( 'keyup change', function () {

                    var val = $(this).val();

                    jpdt_lpr_excel.column( i-1 )
                    .search( val )
                    .draw();
           
            } );

        }
        else if(tipo === 'select'){
        	
        	var select = $('<select class="filters"><option value="">Filtro</option></select>')
        		.prependTo( $(this).empty() )
        		.on( 'change', function() {
        			var val = $.fn.dataTable.util.escapeRegex(
        				$(this).val()
        			);

        			jpdt_lpr_excel.column(i)
        				.search(val ? '^'+val+'$' : '', true, false)
        				.draw();
        		});

        		jpdt_lpr_excel.column(i).data().unique().sort().each(function (d, j) {
        			select.append('<option value="'+d+'">'+d+'</option>')
        		});
        }
        else if(tipo === 'select-inside'){
            
            var select = $('<select class="filters"><option value="">Filtro</option></select>')
                .prependTo( $(this).empty() )
                .on( 'change', function() {
                    var val = $.fn.dataTable.util.escapeRegex(
                        $(this).val()
                    );
                    
                    jpdt_lpr_excel.column(i)
                        .search(val ? '^'+val+'$' : '', true, false)
                        .draw();
                });

                jpdt_lpr_excel.column(i).data().unique().sort().each(function (d, j) {
                    var subStr = d.match(">(.*)</span>");
                    var nome = subStr[1];

                    select.append('<option value="'+nome+'">'+nome+'</option>')
                });
        }
        else if(tipo === 'select-inside-colanterior'){
        
       
            var select = $('<select class="filters">'+
                                '<option value="">Filtro</option>'+
                                '<option value="sim">Sim</option>'+
                                '<option value="não">Não</option>'+
                            '</select>')
                .prependTo( $(this).empty() )
                .on( 'change', function() {
                    var val = $.fn.dataTable.util.escapeRegex(
                        $(this).val()
                    );
                    
                    jpdt_lpr_excel.column(i-1)
                        .search(val ? '^'+val+'$' : '', true, false)
                        .draw();
                });
           
        }
        
    } );
*/ 
    //Fim Aplicar filtros para cada coluna no topo da tabela



    //minmax qtd alt
/*
	$.fn.dataTable.ext.search.push(
        function( settings, data, dataIndex ) {
            var min = parseInt( $('#min-qtd_alt_admin').val(), 10 );
            var max = parseInt( $('#max-qtd_alt_admin').val(), 10 );
            var age = parseFloat( data[4] ) || 0; // use data for the age column
 
            if ( ( isNaN( min ) && isNaN( max ) ) ||
                 ( isNaN( min ) && age <= max ) ||
                 ( min <= age   && isNaN( max ) ) ||
                 ( min <= age   && age <= max ) )
            {
                return true;
            }
            return false;
        }
    );

 */
    

    $(document).on('keyup change', '#min-qtd_alt_admin, #max-qtd_alt_admin', function(){

        jpdt_lpr_excel.draw();

        if ($(this).val()!= '') {
            $(this).addClass('filtro-active');
        }
        else{
            $(this).removeClass('filtro-active');
        }
    });


    $(document).on('keyup change', '.filters', function(){

        if ($(this).val()!= '') {
            $(this).addClass('filtro-active');
        }
        else{
            $(this).removeClass('filtro-active');
        }
    });


    //Ir para o topo da tabela quando se clica em 'anterior', 'nº página', 'próximo'
	jpdt_lpr_excel.on('page.dt', function() {
	  $('html, body').animate({
	    scrollTop: $(".dataTables_wrapper").offset().top
	  }, 'slow');
	});



}); //jQuery